@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
}

p,
div,
span,
a {
  font-family: 'Lato', sans-serif;
}

button:focus {
  outline: none !important;
}

a[href^='/admin/bot'] {
  text-decoration: none !important;
}

.Connect-MainMenu--content-11 {
  width: 2% !important;
}

.MuiFormControl-marginNormal-164 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiTypography-body1-81 {
  padding: 0 !important;
  height: 100%;
}

.MuiTypography-body1-386 {
  padding: 0 !important;
  height: 100%;
}

.MuiTypography-body1-418 {
  padding: 0 !important;
  height: 100%;
}

#swipeable > div > div > div {
  padding: 0 !important;
  height: 100%;
}

.main {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  background: #f7fafe;
}

.row--main {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
}

.title--small {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #3a3f62;
}

.title--main {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 16px;
}

.title-section {
  color: #3a3f62;
  height: 100%;
  background-size: contain !important;
  box-shadow: 0 2px 23px rgba(0, 0, 0, 0.0593014);
  z-index: 1;
}

.title-section .title--small {
  font-size: 16px;
  color: #616581;
  font-weight: normal;
}

.section__inner {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 16px 0;
  position: relative;
}

.login-section {
  height: 100%;
  background: #ffffff;
}

.title-section .section__inner {
  padding: 64px;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.fa-google-plus-g {
  font-size: 18px;
  font-weight: 700;
}

.button-signin:not(.button-small):not(.button--nostyles):hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.button-signin {
  background: #1658f3;
  box-shadow: 0 3px 9px rgba(19, 69, 186, 0.206267);
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 8px 0;
  outline: none;
  border: none;
  padding: 16px 24px;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.button--google {
  background: #f34a38;
  box-shadow: 0 3px 9px rgba(211, 72, 54, 0.206267);
  border-radius: 10px;
  color: #ffffff;
}

.button--google span {
  line-height: 18px;
}

.button--nostyles {
  color: #616581;
  padding: 0;
  background: transparent;
  margin-top: 0;
}

.button--nostyles:hover {
  text-decoration: underline;
}

.button-small {
  position: absolute;
  bottom: 18px;
  right: 24px;
  font-size: 12px;
  color: #3a3f62;
  margin: 0;
  height: 42px;
  background: #ffffff;
}

.button-signup {
  margin: 0 0 0 4px;
  color: #1658f3;
  font-size: 14px;
}

.input-container {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  width: 100%;
}

.form--login {
  margin: 32px 0;
  text-align: center;
  max-width: 60% !important;
}

.form-input {
  margin: 10px 0 24px;
  border: 1px solid #d7d8df;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 24px;
  height: 46px;
  outline-color: #1658f3;
  width: 100%;
}

.form-input::-webkit-input-placeholder {
  color: #b0b2c0;
  font-size: 14px;
  font-weight: 300;
}

.form-input:-ms-input-placeholder {
  color: #b0b2c0;
  font-size: 14px;
  font-weight: 300;
}

.form-input::placeholder {
  color: #b0b2c0;
  font-size: 14px;
  font-weight: 300;
}

.input-label {
  font-size: 14px;
  color: #616581;
  margin: 0;
  font-weight: 300;
}

.platforms {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  bottom: 30px;
}

.platforms-soon {
  margin-left: auto;
}

.platforms__image {
  max-height: 100%;
}

.platforms__title {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.m-l-24px {
  margin-left: 24px;
}

@media (min-width: 992px) {
  .main {
    padding: 50px 90px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main {
    padding: 30px 60px !important;
  }

  .platforms {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .platforms-soon {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .row--main {
    height: 100%;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  main {
  }

  .row--main {
    height: 50%;
  }

  .col-sm-6 {
    -webkit-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 576px) {
  .col-sm-10 {
    -webkit-flex: 0 0 70% !important;
            flex: 0 0 70% !important;
  }
}

.delay-_05s {
  /*animation-delay: 0.05s;*/
}

.delay-_1s {
  /*animation-delay: 0.1s;*/
}

.delay-_15s {
  /*animation-delay: 0.15s;*/
}

.delay-_2s {
  /*animation-delay: 0.2s;*/
}

.delay-_25s {
  /*animation-delay: 0.25s;*/
}

mark {
  border-radius: 50px !important;
  padding: 1px 8.4px !important;
  background: #5a98f7 !important;
  font-size: 14px !important;
  font-family: 'Lato', sans-serif;
  color: white;
  /*white-space: nowrap;*/
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9a9fa8;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #9a9fa8;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #9a9fa8;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #9a9fa8;
}

body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
  margin-left: 8px;
}

.react-autosuggest__input {
  width: 130px;
  height: 38px;
  padding: 2px 8px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  box-shadow: 0 4px 8px rgba(53, 64, 82, 0.32);
  width: 130px;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  z-index: 2;
  border-radius: 4px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(90, 152, 247, 0.1);
}

.footer {
  margin: 9px 20px;
  font-size: 12px;
  color: #777;
}

/* Inspirated from  http://www.alessioatzeni.com/wp-content/tutorials/html-css/CSS3-loading-animation-loop/index.html */
.Loader-small {
  text-align: center;
}

.ouro {
  position: relative;
  display: inline-block;
  height: 46px;
  width: 46px;
  margin: 1em;
  border-radius: 50%;
  background: none repeat scroll 0 0 #dddddd;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.ouro:after {
  content: '';
  position: absolute;
  top: 9px;
  left: 9px;
  display: block;
  height: 28px;
  width: 28px;
  background: none repeat scroll 0 0 #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ouro > span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.anim {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: linear-gradient(
    285.2deg,
    rgb(90, 152, 247) -35.9%,
    rgb(22, 88, 243) 61.17%
  );
  /*background: none repeat scroll 0 0 #1658F3;*/
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left 3s infinite;
  animation: ui-spinner-rotate-left 3s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
}

.left .anim {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.right .anim {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
}

/* v2 */
.ouro2 .anim {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.ouro2 .right .anim {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

/* v3 */
.ouro3 .anim {
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 3s;
  animation-timing-function: linear;
}

.ouro3 .right .anim {
  -webkit-animation-name: ui-spinner-rotate-right;
  -webkit-animation-delay: 0;
  -webkit-animation-delay: 1.5s;
  animation-name: ui-spinner-rotate-right;
  animation-delay: 0;
  animation-delay: 1.5s;
}

/* round variation */
.round .ouro:after {
  display: none;
}

/* double variation */
.double .ouro:after {
  height: 13px;
  width: 13px;
  left: 7px;
  top: 7px;
  border: 10px solid #ddd;
  background: transparent;
  box-shadow: none;
}

@keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.Loader {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Loader__spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #5a98f7;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.DateControl {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto 16px;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  min-height: 70px;
}

.frequency {
  text-align: center;
  margin-top: 16px;
}

.frequency__button {
  background: #ffffff;
  border: 1px solid #ebebef;
  box-sizing: border-box;
  color: #616581;
  padding: 14px 18px;
  /*width: 90px;*/
  text-align: center;
  font-size: 14px;
  outline: none !important;
}
.frequency__button:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}
.frequency__button.active {
  background-color: #5a98f7;
  color: #ffffff;
  border-color: #5a98f7;
}

.frequency__button:first-of-type {
  border-radius: 10px 0 0 10px;
}

.frequency__button:last-of-type {
  border-radius: 0 10px 10px 0;
}

@media (max-width: 767px) {
  .frequency {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    outline: none;
    max-width: 300px;
    -webkit-align-self: center;
            align-self: center;
  }
  .frequency__button {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    min-width: 60px;
    padding: 14px 0;
  }

  .frequency__button:first-of-type {
    border-radius: 10px 0 0 0;
  }
  .frequency__button:nth-of-type(2) {
    border-radius: 0 10px 0 0;
  }
  .frequency__button:nth-of-type(3) {
    border-radius: 0 0 0 10px;
  }
  .frequency__button:last-of-type {
    border-radius: 0 0 10px 0;
  }
}

.react-bootstrap-daterangepicker-container input {
  width: 100%;
  height: 32px;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  margin: 16px auto 0;
  border: 1px solid #ebebef;
  outline-color: #5a98f7;
  cursor: pointer;
}

.react-bootstrap-daterangepicker-container {
  text-align: center;
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
}

@media (max-width: 767px) {
  .react-bootstrap-daterangepicker-container {
    width: 100%;
  }
}

.message-group {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 220px);
  width: 100%;
  padding: 40px;
}

.add-btn {
  right: 16px;
  bottom: 16px;
  position: absolute;
}

.atom-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.dlt-msg {
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -20px;
  z-index: 5;
}

.MuiGrid-typeItem-135.MuiGrid-grid-xs-12-171.MuiGrid-grid-sm-8-180 {
  width: 60%;
  max-width: 60%;
}

/*.Select-menu-outer {
    top: auto!important;
    bottom: 100%!important;
}*/

.quick-reply {
  border-radius: 35px;
  color: #2668e8;
  outline: none;
  text-align: center;
  margin: 3px;
  border: 1px solid #2668e8;
  background-color: #ffffff;
  padding: 10px 16px 24px;
  font-size: 14px;
  line-height: 16px;
  resize: none;
  height: 36px;
}

.rep-btns-container {
  background-color: white;
  border-radius: 0 0 10px 10px;
}

.add-button {
  color: #0084ff;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin: auto;
  border-radius: 0 0 10px 10px;
}

.add-reply {
  margin: 0 0 5px;
  min-width: 186px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 1px solid #1658f3;
  border-radius: 50px;
  font-size: 14px;
  line-height: 16px;
  height: 38px;
  display: -webkit-flex;
  display: flex;
}

.add-reply-text {
  padding: 10px;
  color: #1658f3;
}

.button {
  width: 100%;
  color: #0084ff;
  font-weight: 500;
  text-align: center;
  height: 36px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}

.rangeslider-horizontal {
  height: 10px !important;
}

.rep-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
}

.message {
  width: 280px;
  position: relative;
  border: 1px solid #e5e5e5;
  padding: 0;
  border-radius: 10px;
  margin: 0 10px;
  background-color: #f8f8f8;
}

.generic-container {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.generic-msg {
  min-width: 400px;
  margin: 5px;
  padding: 20px;
}

.add-element {
  color: grey;
  width: 100px;
  text-align: center;
  padding-top: 80px;
}

.NewMessage-paper-300 {
  max-width: 600px;
  min-width: 400px;
  width: auto !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/*.container, .backdrop, textarea {*/
/*width: 100%;*/
/*}*/
.paramsAnother {
  width: 400px !important;
  margin: 0;
}

.highlights,
textarea {
  padding: 10px;
  font: 14px/23px 'Open Sans', sans-serif;
  letter-spacing: 0px;
}

.container {
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-text-size-adjust: none;
}

.backdrop {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  /*border-radius: 10px;*/
  background-color: #fff;
  overflow: auto;
  width: 100%;
  height: 140px;
  pointer-events: none;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  height: 140px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  /*border-radius: 10px;*/
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  outline: none;
  width: 100%;
}

