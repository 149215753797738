.DateControl {
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px;
  justify-content: center;
  width: 100%;
  min-height: 70px;
}

.frequency {
  text-align: center;
  margin-top: 16px;
}

.frequency__button {
  background: #ffffff;
  border: 1px solid #ebebef;
  box-sizing: border-box;
  color: #616581;
  padding: 14px 18px;
  /*width: 90px;*/
  text-align: center;
  font-size: 14px;
  outline: none !important;
}
.frequency__button:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}
.frequency__button.active {
  background-color: #5a98f7;
  color: #ffffff;
  border-color: #5a98f7;
}

.frequency__button:first-of-type {
  border-radius: 10px 0 0 10px;
}

.frequency__button:last-of-type {
  border-radius: 0 10px 10px 0;
}

@media (max-width: 767px) {
  .frequency {
    display: flex;
    flex-wrap: wrap;
    outline: none;
    max-width: 300px;
    align-self: center;
  }
  .frequency__button {
    flex-basis: 50%;
    min-width: 60px;
    padding: 14px 0;
  }

  .frequency__button:first-of-type {
    border-radius: 10px 0 0 0;
  }
  .frequency__button:nth-of-type(2) {
    border-radius: 0 10px 0 0;
  }
  .frequency__button:nth-of-type(3) {
    border-radius: 0 0 0 10px;
  }
  .frequency__button:last-of-type {
    border-radius: 0 0 10px 0;
  }
}
