.react-bootstrap-daterangepicker-container input {
  width: 100%;
  height: 32px;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  margin: 16px auto 0;
  border: 1px solid #ebebef;
  outline-color: #5a98f7;
  cursor: pointer;
}

.react-bootstrap-daterangepicker-container {
  text-align: center;
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
}

@media (max-width: 767px) {
  .react-bootstrap-daterangepicker-container {
    width: 100%;
  }
}
